import { trigger, animate, style, query, transition, stagger, state, group } from "@angular/animations";

export const feedAnimations = [
	trigger("onRenderUp", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateY(-100px)" }),
			animate("500ms", style({ opacity: 1, transform: "translateY(0)" })),
		]),
		transition(":leave", [animate("200ms", style({ opacity: 0, transform: "translateY(-100px)" }))]),
	]),

	trigger("onRenderBottom", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateY(100px)" }),
			animate("500ms", style({ opacity: 1, transform: "translateY(0)" })),
		]),
		transition(":leave", [animate("200ms", style({ opacity: 0, transform: "translateY(100px)" }))]),
	]),

	trigger("onNextStep", [
		transition(":enter", [
			style({ opacity: 0, transform: "translateY(-100px)" }),
			animate("500ms", style({ opacity: 1, transform: "translateY(0)" })),
		]),
	]),

	trigger("slideUpList", [
		transition(":enter, :leave", [
			query(
				":enter",
				[
					style({ transform: "translateY(-100px)", opacity: 0 }),
					stagger("100ms", [animate("300ms ease-in", style({ transform: "translateY(0)", opacity: 1 }))]),
				],
				{ optional: true },
			),
			query(
				":leave",
				[
					style({ transform: "translateY(0)", opacity: 1 }),
					stagger("50ms", [animate("300ms ease-out", style({ transform: "translateY(-100px)", opacity: 0 }))]),
				],
				{ optional: true },
			),
		]),
	]),

	trigger("slideInRightList", [
		transition(":enter, :leave", [
			query(
				":enter",
				[
					style({ transform: "translateX(100%)", opacity: 0 }),
					stagger("100ms", [animate("300ms ease-in", style({ transform: "translateX(0)", opacity: 1 }))]),
				],
				{ optional: true },
			),
			query(
				":leave",
				[
					style({ transform: "translateX(0)", opacity: 1 }),
					stagger("50ms", [animate("300ms ease-out", style({ transform: "translateX(100%)", opacity: 0 }))]),
				],
				{ optional: true },
			),
		]),
	]),

	trigger("slideInLeftList", [
		transition(":enter, :leave", [
			query(
				":enter",
				[
					style({ transform: "translateX(-100%)", opacity: 0 }),
					stagger("100ms", [animate("300ms ease-in", style({ transform: "translateX(0)", opacity: 1 }))]),
				],
				{ optional: true },
			),
			query(
				":leave",
				[
					style({ transform: "translateX(0)", opacity: 1 }),
					stagger("50ms", [animate("300ms ease-out", style({ transform: "translateX(-100%)", opacity: 0 }))]),
				],
				{ optional: true },
			),
		]),
	]),

	trigger("slideInLeftListCompetencies", [
		transition(":enter, :leave", [
			query(
				":enter",
				[
					style({ transform: "translateX(-100%)", opacity: 0 }),
					stagger("200ms", [animate("300ms ease-in", style({ transform: "translateX(0)", opacity: 1 }))]),
				],
				{ optional: true },
			),
		]),
	]),
];
